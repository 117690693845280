<template>
  <div class="widget widget-horizontal-works">
    <div class="widget-heading d-flex align-items-center">
      <h5>
        Obras <b-link
          v-if="$checkPermission('criar-obra') && worksPipeline.length > 0"
          :to="{name: 'works-new'}"
        >
          <i class="fa-regular fa-circle-plus" />
        </b-link>
      </h5>
    </div>

    <div class="widget-content">
      <b-row v-if="isLoadingStatuses || isLoadingPipeline">
        <b-col class="p-3 text-center">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col
          v-for="(element, idx) in worksPipeline"
          :key="idx"
        >
          <b-button
            block
            size="sm"
            class="mb-2 mb-sm-0"
            :class="showworks === element.id ? `bg-${element.color}` : 'bg-light text-dark'"
            @click="showworks = element.id"
          >
            {{ element.name }}
          </b-button>
        </b-col>
      </b-row>
      <div
        v-if="!isLoadingStatuses && !isLoadingPipeline && worksPipeline.length == 0"
        id="workList"
        class="mt-2 mb-0"
      >
        <b-list-group
          class="mb-0"
          horizontal
        >
          <b-list-group-item
            v-if="filteredWorks.length === 0"
            class="bg-transparent w-100 text-center"
          >
            <p>Nenhuma status para obras criado.</p>
            <p>
              <b-button
                variant="outline-primary"
                :to="{name: 'works'}"
              >
                Clique aqui para começar a criar obras
              </b-button>
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div
        v-if="!isLoadingStatuses && !isLoadingPipeline && !isLoading && worksPipeline.length > 0"
        id="workList"
        class="mt-2 mb-0"
      >
        <b-list-group
          class="mb-0"
          horizontal
        >
          <b-list-group-item
            v-if="filteredWorks.length === 0"
            class="bg-transparent w-100 d-flex justify-content-center align-items-center"
          >
            <span class="p-5">Nenhuma obra encontrada com o status selecionado</span>
          </b-list-group-item>

          <b-list-group-item
            v-for="work in filteredWorks"
            :key="work.id"
            class="bg-transparent px-2"
          >
            <b-link :to="{ name: 'work', params: { workId: Number(work.id) } }">
              <b-card
                id="cardWorks"
                class="mb-1"
                header-bg-variant="transparent"
                header-class="d-flex justify-content-start align-items-center px-2"
                footer-bg-variant="transparent"
                footer-class="p-2"

                no-body
                style="cursor: pointer"
              >
                <template #header>
                  <small
                    class="text-truncate d-inline-block font-weight-bolder"
                    style="max-width: 120px"
                  >
                    {{ work.name }}
                  </small>
                </template>
                <b-card-body class="p-0">
                  <b-img-lazy
                    :src="work.featured_image"
                    :alt="work.name"
                    class="imgWork"
                    rounded="bottom"
                  />
                </b-card-body>
                <template #footer>
                  <p
                    v-if="work.architectable"
                    class="my-1 text-truncate d-inline-block font-weight-bolder"
                    style="max-width: 120px"
                  >
                    <small><i class="fa-solid fa-circle-a fa-lg mr-1" /><strong>{{ work.architectable.name }}</strong></small>
                  </p>
                  <p
                    v-else
                    class="my-1 text-truncate d-inline-block font-weight-bolder"
                    style="max-width: 120px"
                  >
                    <small><i class="fa-solid fa-circle-a fa-lg mr-1" /><strong>Não informado</strong></small>
                  </p>
                  <p
                    v-if="work.sum_valuestep5"
                    class="my-1 text-truncate d-inline-block font-weight-bolder"
                    style="max-width: 120px"
                  >
                    <small><i class="fa-sharp fa-solid fa-circle-dollar fa-lg mr-1" /><strong>R$ {{ work.sum_valuestep5 | formatPrice }}</strong></small>
                  </p>
                  <p
                    v-else
                    class="my-1 text-truncate d-inline-block font-weight-bolder"
                    style="max-width: 120px"
                  >
                    <small><i class="fa-sharp fa-solid fa-circle-dollar fa-lg mr-1" /><strong>R$ - </strong></small>
                  </p>
                </template>
              </b-card>
            </b-link>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ApiService from '../../common/api.service'

export default {
  name: 'ProfileWorks',
  filters: {},

  data () {
    return {
      works: [],
      isLoading: false,
      isLoadingStatuses: false,
      isLoadingPipeline: false,
      lastUpdate: null,
      error: null,
      showworks: null,
      worksPipeline: [],
      filteredWorks: [],
      statusIds: [],
      companyId: null,
      statuses: []
    }
  },
  computed: {

  },
  watch: {
    showworks: function (value) {
      Vue.$cookies.set('arktop_showworks', {
        id: value
      })

      if (value > 0) {
        const pipe = this.worksPipeline.find(({ id }) => id === value)

        if (pipe) {
          this.filteredWorks = pipe.works
        }
      } else {
        this.filteredWorks = []
      }
    }
  },
  created: function () {
    if (this.$cookies.isKey('user_active_company')) {
      this.companyId = this.$cookies.get('user_active_company').id
    }
  },

  mounted: function () {
    this.getStatuses()
  },
  methods: {
    getStatuses: async function () {
      this.isLoadingStatuses = true
      /**
       * Não estou especificando a empresa ainda.
       * Isso terá relação com a empresa assinante
       */
      await ApiService.get('/work/getStatuses/' + this.companyId)
        .then((response) => {
          this.statuses = response.data.statuses

          if (this.statuses.length > 0) {
            this.statusIds = []
            this.statuses.forEach(element => {
              if (element.show_on_pipeline === 1) {
                this.statusIds.push(element.id)
              }
            })
            this.getWorksPipelineData()
          }
        })
        .finally(() => {
          this.isLoadingStatuses = false
        })
        .catch((error) => {
          this.$showToast('Erro', 'danger', 'Ocorreu um erro durante a operação.')
          console.log(error)
        })
    },
    getWorksPipelineData: function () {
      this.isLoadingPipeline = true
      /**
       * Não estou especificando a empresa ainda.
       * Isso terá relação com a empresa assinante
       */
      if (this.companyId === null) {
        this.isLoadingPipeline = false
        Vue.swal({
          title: 'Erro!',
          text: 'Empresa não selecionada.',
          icon: 'error'
        })
        return
      }
      if (this.statusIds.length === 0) {
        this.isLoadingPipeline = false
        Vue.swal({
          title: 'Erro!',
          text: 'A empresa não criou nenhum status para classificar as obras.',
          icon: 'error'
        })
        return
      }
      ApiService.get(`/work/getWorksPipelineData/${this.companyId}/${this.statusIds}`)
        .then((response) => {
          this.worksPipeline = response.data.worksPipeline
          this.worksPipeline.forEach((pipe) => {
            pipe.works.forEach((work) => {
              if (work.featured_image.indexOf('placeholder.png') > -1) {
                work.featured_image = '/images/placeholder.png'
              }
            })
            if (this.$cookies.isKey('arktop_showworks')) {
              this.showworks = this.$cookies.get('arktop_showworks').id
            } else {
              this.showworks = this.statusIds[0]
            }
          })
        })
        .finally(() => {
          this.isLoadingPipeline = false
        })
    }

  }
}
</script>

<style scoped>

    a {
        text-decoration: none !important;
    }
    .card-link {
        position: relative;
    }
    .container {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .tag {
        float: left;
        position: absolute !important;
        left: 15px !important;
        bottom: 40px !important;
    }
    .workstatus {
        float: left;
        position: absolute !important;
        left: 15px !important;
        bottom: 15px !important;
    }
    .company-img {
        position: absolute !important;
        right: 15px !important;
        bottom: 15px !important;
    }
    .btn-see-work {
        position: absolute !important;
        top: 80% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
    }
    .avatar {
        width: 80px;
    }

    #workList {
        width: auto;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    #cardWorks {
        width: 130px;
        cursor: pointer;
    }

    .imgWork {
        width: 130px;
        height: 130px;
        object-fit: cover;
    }
    .new-work {
        width: 130px;
        height: 130px;
        border-color: gainsboro;
        border-width: thin;
    }
    .list-group-item {
        border: unset;
    }
</style>
