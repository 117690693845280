var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoadingWork && _vm.workId)?_c('div',[_c('b-navbar',{staticClass:"border-bottom border-secondary bg-white",staticStyle:{"height":"54px"},attrs:{"toggleable":"lg"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"w-100 p-3",attrs:{"fill":""}},[_c('b-nav-item',{staticClass:"my-auto",class:_vm.showmenu == 'homework' ? 'active' : null,attrs:{"to":{
            name: 'work',
            params: { workId: _vm.workId },
          }},on:{"click":function($event){return _vm.$store.commit('updateWorkMenuName', null)}}},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.workName)+" ")])]),_c('b-nav-item',{staticClass:"my-auto",class:_vm.showmenu == 'informacoes' ? 'active' : null,attrs:{"to":{
            name: 'work-informations',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Informações ")]),_c('b-nav-item',{staticClass:"my-auto",class:_vm.showmenu == 'orcamentos' ? 'active' : null,attrs:{"to":{
            name: 'work-scope-index',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Orçamentos ")]),_c('b-nav-item',{staticClass:"my-auto",class:_vm.showmenu == 'suprimentos' ? 'active' : null,attrs:{"to":{
            name: 'work-biddings',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Suprimentos ")]),_c('b-nav-item',{staticClass:"my-auto",class:_vm.showmenu == 'cronogramas' ? 'active' : null,attrs:{"to":{
            name: 'work-schedule',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Cronogramas ")]),_c('b-nav-item',{staticClass:"my-auto",class:_vm.showmenu == 'execucao' ? 'active' : null,attrs:{"to":{
            name: 'work-measurements',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Execução ")]),_c('b-nav-item',{staticClass:"my-auto",class:_vm.showmenu == 'financeiro' ? 'active' : null,attrs:{"to":{
            name: 'work-invoices',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Financeiro ")])],1)],1)],1),(_vm.showmenu == 'informacoes')?_c('b-navbar',{staticClass:"bg-white",staticStyle:{"height":"54px"},attrs:{"toggleable":"lg"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"w-100 p-3",attrs:{"fill":""}},[_c('b-nav-item',{class:_vm.showSubmenu == 'informations-panel' ? 'active' : null,attrs:{"to":{
            name: 'work-informations',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Painel de Informações ")]),_c('b-nav-item',{class:_vm.showSubmenu == 'team' ? 'active' : null,attrs:{"to":{
            name: 'work-team',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("workteam"))+" ")]),_c('b-nav-item',{class:_vm.showSubmenu == 'projects' ? 'active' : null,attrs:{"to":{
            name: 'work-projects',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("workprojects"))+" ")]),_c('b-nav-item',{class:_vm.showSubmenu == 'images' ? 'active' : null,attrs:{"to":{
            name: 'work-images',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("workimages"))+" ")]),_c('b-nav-item',{class:_vm.showSubmenu == 'documents' ? 'active' : null,attrs:{"to":{
            name: 'work-documents',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("workdocuments"))+" ")])],1)],1)],1):_vm._e(),(_vm.showmenu == 'orcamentos')?_c('b-navbar',{staticClass:"bg-white",staticStyle:{"height":"54px"},attrs:{"toggleable":"lg"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"w-100 p-3",attrs:{"fill":""}},[_c('b-nav-item',[_vm._v("Painel e Escopos")]),_c('b-nav-item',{class:_vm.showSubmenu == 'escopos' ? 'active' : null,attrs:{"to":{
            name: 'work-scope-index',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("workscopes"))+" ")]),_c('b-nav-item',{class:_vm.showSubmenu == 'budget' ? 'active' : null,attrs:{"to":{
            name: 'work-budget',
            params: { workId: _vm.workId },
          }}},[_c('a',[_vm._v(_vm._s(_vm.$t("workbudget")))])]),_c('b-nav-item',[_vm._v("Gestão por Fases")]),_c('b-nav-item',[_vm._v("Gestão por Verbas")]),_c('b-nav-item',[_vm._v("Gestão por Pav / Amb")])],1)],1)],1):_vm._e(),(_vm.showmenu == 'cronogramas')?_c('b-navbar',{staticClass:"bg-white",staticStyle:{"height":"54px"},attrs:{"toggleable":"lg"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"w-100 p-3",attrs:{"fill":""}},[_c('b-nav-item',[_vm._v("Painel de Cronogramas")]),_c('b-nav-item',{attrs:{"to":{
            name: 'work-hours',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Horário de Trabalho ")]),_c('b-nav-item',{attrs:{"to":{
            name: 'work-schedule',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("workschedule"))+" ")])],1)],1)],1):_vm._e(),(_vm.showmenu == 'execucao')?_c('b-navbar',{staticClass:"bg-white",staticStyle:{"height":"54px"},attrs:{"toggleable":"lg"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"w-100 p-3",attrs:{"fill":""}},[_c('b-nav-item',[_vm._v("Painel de Execução")]),_c('b-nav-item',{attrs:{"to":{
            name: 'work-hirings',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("work-hirings"))+" ")]),_c('b-nav-item',{attrs:{"to":{
            name: 'work-measurements',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("work-measurements"))+" ")]),_c('b-nav-item',{attrs:{"to":{
            name: 'work-diary',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("workdiary"))+" ")])],1)],1)],1):_vm._e(),(_vm.showmenu == 'suprimentos')?_c('b-navbar',{staticClass:"bg-white",staticStyle:{"height":"54px"},attrs:{"toggleable":"lg"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"w-100 p-3",attrs:{"fill":""}},[_c('b-nav-item',[_vm._v("Painel de Suprimentos")]),_c('b-nav-item',{class:_vm.showSubmenu == 'suppliers' ? 'active' : null,attrs:{"to":{
            name: 'work-suppliers',
            params: { workId: _vm.workId },
          }}},[_c('a',[_vm._v(_vm._s(_vm.$t("work-suppliers")))])]),_c('b-nav-item',{class:_vm.showSubmenu == 'concorrencias' ? 'active' : null,attrs:{"to":{
            name: 'work-biddings',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Mapas de Concorrências ")]),_c('b-nav-item',{class:_vm.showSubmenu == 'contratacoes' ? 'active' : null,attrs:{"to":{
            name: 'work-hirings',
            params: { workId: _vm.workId },
          }}},[_vm._v(" Mapa de Contratações ")])],1)],1)],1):_vm._e(),(_vm.showmenu == 'financeiro')?_c('b-navbar',{staticClass:"bg-white",staticStyle:{"height":"54px"},attrs:{"toggleable":"lg"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"w-100 p-3",attrs:{"fill":""}},[_c('b-nav-item',[_vm._v("Painel Financeiro")]),_c('b-nav-item',{attrs:{"to":{
            name: 'work-invoices',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("work-invoices"))+" ")]),_c('b-nav-item',{attrs:{"to":{
            name: 'work-cash-flow',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("work-cash-flow"))+" ")]),_c('b-nav-item',{attrs:{"to":{
            name: 'work-electronic-invoices',
            params: { workId: _vm.workId },
          }}},[_vm._v(" "+_vm._s(_vm.$t("work-electronic-invoices"))+" ")])],1)],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }